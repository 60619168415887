import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { OidcProvider } from "redux-oidc";
import { BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import { store } from "./store";
import App from "./components/App";
import userManager from "./Utils/Oidc/userManager";
import "./Assets/css/main.scss";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const history = createBrowserHistory();

ReactDOM.render(
  <BrowserRouter history={history}>
    <Provider store={store}>
      <OidcProvider userManager={userManager} store={store}>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </OidcProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("app")
);
