import { manageToolbarSelectionsActionType } from "./actionTypes";

export const addToolbarFilter = (payload) => {
  return {
    type: manageToolbarSelectionsActionType.ADD_FILTER,
    payload,
  };
};

export const resetToolbarFilters = () => {
  return {
    type: manageToolbarSelectionsActionType.RESET_FILTERS,
    payload: undefined,
  };
};
