import { createUserManager } from "redux-oidc";
const settings = {
  client_id: process.env.CLIENT_ID, //"12345",
  client_secret: process.env.CLIENT_SECRET, //"SuperSecretPassword",
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ""
  }/callback`,
  // redirect_uri: process.env.OIDC_REDIRECT_URL,//"http://localhost:8080/callback",
  response_type: "code",
  scope: "openid profile MCUPortalCommonAPI MCUPortalAdminAPI",
  authority: process.env.OIDC_AUTHORITY, //"https://localhost:5000",
  post_logout_redirect_uri: `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ""}`, //'https://localhost:5000/Accounts/Login',
  silent_redirect_uri: `${window.location.protocol}//${
    window.location.hostname
  }${window.location.port ? `:${window.location.port}` : ""}/silent_renew.html`,
  automaticSilentRenew: false,
  filterProtocolClaims: true,
  loadUserInfo: true,
};

const userManager = createUserManager(settings);

export default userManager;
